import React from 'react'
import {useRouter} from 'next/router'

import type {MedicalTransporterType, UserPermissionType} from '@prisma/client'
import {useAuth0} from '@auth0/auth0-react'
import {gql} from '@apollo/client'
import {isStaffEmail} from '@ambler/shared'
import {useSessionStorage} from '../../hooks/use-storage'
import {useMutation} from '../../hooks/use-mutation'
import {getUpdateDevicePayload} from '../../lib/firebase-messaging'
import type {BO_logoutDeviceMutation_, BO_logoutDeviceMutationVariables} from './auth.generated'

type SignInOptions = {
  callbackUrl?: string
}

type MtAcl = {
  canRead: boolean
  canWrite: boolean
  mt: MedicalTransporter
}

const logoutDeviceMutation = gql`
  mutation BO_logoutDeviceMutation($data: PushNotificationsUpsertDeviceDataInput!) {
    pushNotificationsUpsertDevice(data: $data)
  }
`

export const useForcedMtId = (email: string) => {
  const isStaff = isStaffEmail(email)
  const [isLoading, setIsLoading] = React.useState(true)
  const {query} = useRouter()
  const [forcedMtId, setForcedMtId] = useSessionStorage<string>('bo.dashboard.forcedMtId')

  React.useEffect(() => {
    if (!isStaff) {
      return
    }
    if (query.forceMt && query.forceMt !== forcedMtId) {
      setForcedMtId(query.forceMt as string)
    }
    setIsLoading(false)
  }, [query.forceMt, forcedMtId, setForcedMtId, isStaff])
  return isStaff ? ([forcedMtId, isLoading] as const) : ([null, false] as const)
}

export const useAuth = (): {
  user: {
    id: string
    auth0Id: string
    name: string
    email: string
    role: string
  }
  mtAcls: MtAcl[]
  isAuthenticated: boolean
  isEmailConfirmed: boolean
  isLoading: boolean
  isConnectAs: boolean
  signIn: (arg0?: SignInOptions) => void
  signOut: (redirectToHome?: boolean) => void
  permissions: {id: string; type: UserPermissionType}[]
} => {
  const [logoutDevice] = useMutation<BO_logoutDeviceMutation_, BO_logoutDeviceMutationVariables>({
    mutation: logoutDeviceMutation,
  })

  const {user, isAuthenticated, isLoading, loginWithRedirect, logout} = useAuth0()

  const [forcedMtId, forcedMtIdLoading] = useForcedMtId(user?.email)

  const userProfile = user?.['https://api.ambler.fr/']
  const isConnectAs = !forcedMtIdLoading && Boolean(forcedMtId)

  const {mtAcls, role, id, name, email, auth0Id, isEmailConfirmed, permissions} = userProfile || {}

  return {
    user: {
      id,
      auth0Id,
      name,
      email,
      role,
    },
    mtAcls,
    isAuthenticated,
    isEmailConfirmed,
    permissions,
    isLoading: isLoading || forcedMtIdLoading,
    isConnectAs,
    signIn: (options: SignInOptions = {}) => {
      const {callbackUrl} = options
      const params = {
        redirectUri: callbackUrl ? callbackUrl : `${process.env.AMBLER_BO_URL}`,
      }
      return loginWithRedirect(params)
    },
    signOut: async () => {
      try {
        await logoutDevice({
          variables: {
            data: {
              ...(await getUpdateDevicePayload()),
              state: 'LOGGED_OUT',
              fcmToken: null,
            },
          },
        })
      } catch (_err) {
        // no op but we want the logout to happen
      }
      return logout({returnTo: process.env.AMBLER_BO_URL})
    },
  }
}

type MedicalTransporter = {
  id: string
  name?: string
  type: MedicalTransporterType
}

type MainMt = {
  canRead: boolean
  canWrite: boolean
  mt: MedicalTransporter
}

export const useMainMt = (): MainMt => {
  const {isLoading, isAuthenticated, user, mtAcls} = useAuth()
  const [forcedMtId, forcedMtIdLoading] = useForcedMtId(user?.email)

  if (isLoading || forcedMtIdLoading || !isAuthenticated) {
    return null
  }

  if (forcedMtId) {
    return {
      canRead: true,
      canWrite: true,
      mt: {id: forcedMtId, type: 'DISPATCHER'},
    }
  }

  return mtAcls?.[0]
}
